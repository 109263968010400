<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Documents
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['update:fleets']">
        <button
          @click="newDocument"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12"
            />
          </svg>
          <span>Upload Document</span>
        </button>
      </is-authorized>
    </div>
    <div v-if="viewState == 'Idle'">
      <Table
        v-if="documents.length > 0"
        :columns="columns"
        :data="documents"
        :totalRecords="paginationOptions.totalRecords"
        :hasPagination="true"
        :currentPage="paginationOptions.currentPage"
        :totalPages="paginationOptions.totalPages"
        :initialSortOptions="sortOptions"
      >
        <template v-slot:actions="props">
          <div class="flex justify-end space-x-2">
            <is-authorized :permissions="['manage:fleets', 'read:fleets']">
              <button
                v-if="props.row.content_type == 'application/pdf'"
                @click="viewDoc(props.row)"
                type="button"
                class="flex items-center bg-orange-200 border hover:bg-orange-300 border-orange-400 hover:border-orange-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
              >
                <svg
                  class="stroke-current h-5 w-5"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <circle cx="11" cy="11" r="8" />
                  <path d="M21 21l-4.35-4.35" />
                </svg>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'read:fleets']">
              <button
                @click="downloadDoc(props.row)"
                class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                name="Edit Published Doc"
              >
                <svg
                  class="stroke-current h-5 w-5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3"
                  />
                </svg>
              </button>
            </is-authorized>
            <is-authorized
              :permissions="['manage:fleets', 'read:fleets', 'update:fleets']"
            >
              <button
                v-if="canEditDelete"
                @click="editDocument(props.row)"
                class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                name="Edit Published Doc"
              >
                <svg
                  class="stroke-current h-5 w-5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                  />
                </svg>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                v-tooltip.top="'Delete'"
                v-if="canEditDelete"
                @click="deleteDocument(props.row)"
                class="flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                name="Delete Published Doc"
              >
                <svg
                  class="stroke-current h-5 w-5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                  />
                </svg>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                v-tooltip.top="'Detach'"
                v-if="canEditDelete"
                @click="deleteDocumentLink(props.row)"
                class="flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                name="Delete Published Doc"
              >
                <svg
                  class="stroke-current h-5 w-5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
              </button>
            </is-authorized>
          </div>
        </template>
      </Table>
      <div v-else class="text-center py-24">
        <p>There are no documents to show.</p>
      </div>
    </div>
    <div
      v-if="viewState == 'Loading'"
      class="flex items-center justify-center py-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <portal to="overlay-outlet">
      <ViewDocument
        :showing="viewingDocument.panelOpen"
        :document="viewingDocument.doc"
        @close="viewingDocument.panelOpen = false"
      />
    </portal>
    <portal to="overlay-outlet">
      <Panel
        :showing="addEditDocumentPanelShowing"
        @close="closePanel"
        :title="
          editingDocument.document_id === undefined
            ? 'Add Document'
            : 'Edit Document'
        "
      >
        <UploadPublishedDoc
          :document="editingDocument"
          :showAddExisting="true"
          :resource="documentResource"
          @complete="closeDocumentPanel"
        />
      </Panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
const Panel = () => import("@/components/Panel.vue");
const ViewDocument = () => import("@/components/ViewDocument.vue");
const Spinner = () => import("@/components/Spinner.vue");
const ProgressBar = () => import("@/components/ProgressBar.vue");
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const AddEditDocument = () =>
  import("@/components/Fleets/Edit/AddEditDocument.vue");
const Table = () => import("@/components/Table.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");
const UploadPublishedDoc = () =>
  import("@/components/Faqs/UploadPublishedDoc.vue");

export default {
  name: "FaqDocuments",
  components: {
    Panel,
    ViewDocument,
    ProgressBar,
    Notification,
    ConfirmAlert,
    AddEditDocument,
    Spinner,
    Table,
    IsAuthorized,
    UploadPublishedDoc,
  },
  props: {
    faq: Object,
  },
  data() {
    return {
      viewState: "Idle",
      editingDocument: {},
      addEditDocumentPanelShowing: false,
      viewingDocumentOpen: false,
      documents: [],
      document: {},
      isUploading: false,
      uploadProgress: 0,
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      editingDoc: {
        visibility: "Internal",
      },

      paginationOptions: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
      },
      sortOptions: {
        field: "created_at",
        isAsc: false,
        pageSize: 50,
      },
      columns: [
        {
          title: "Name",
          field: "name",
          selector: (row) => row.name,
        },
        {
          title: "Visibility",
          field: "visibility",
          selector: (row) => row.visibility,
        },
        {
          title: "Created",
          field: "created_at",
          selector: (row) => row.created_at,
          type: "date",
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          widthClass: "w-8",
        },
      ],
      viewingDocument: {
        doc: {},
        panelOpen: false,
      },
      documentResource: null,
    };
  },
  async mounted() {
    await this.loadDocuments();
    this.documentResource = {
      resource_type: "faq",
      resource_id: this.faq.faq_id,
    };
  },
  computed: {
    canEditDelete: function () {
      return this.$store.state.userRoles.includes("admin") ? true : true; //update
    },
  },
  methods: {
    downloadDoc: async function (document) {
      try {
        let doc = await this.faqService.getDocumentById(
          this.faq.faq_id,
          document.document_id,
        );
        window.open(doc.access_url);
        console.log(doc);
      } catch (error) {
        console.log(error);
      }
    },
    closePanel: function () {
      this.editingDoc = {
        visibility: "Internal",
      };
      this.addEditDocumentPanelShowing = false;
    },
    loadDocuments: async function () {
      try {
        this.viewState = "Loading";
        let result = await this.faqService.getDocuments(this.faq.faq_id);
        this.viewState = "Idle";
        this.documents = result.data;
        this.paginationOptions.totalRecords = result.count;
        this.paginationOptions.currentPage = result.page;
        this.paginationOptions.totalPages = result.page_count;
      } catch (err) {
        this.viewState = "Error";
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "Unable to load documents for this vehicle",
            );
          },
          {
            position: "top-right",
          },
        );
      }
    },
    viewDoc: async function (document) {
      console.log(document);
      let doc = await this.faqService.getDocumentById(
        this.faq.faq_id,
        document.document_id,
      );
      this.viewingDocument = {
        doc: doc,
        panelOpen: true,
      };
    },

    newDocument: function () {
      this.editingDocument = {};
      this.addEditDocumentPanelShowing = true;
    },
    editDocument: function (document) {
      this.editingDocument = {
        ...document,
      };
      this.addEditDocumentPanelShowing = true;
    },
    closeDocumentPanel: async function () {
      this.document = this.editingDocument;
      this.addEditDocumentPanelShowing = false;
      this.loadDocuments();
      // if (this?.response?.isExisting === true) {
      // }
      // else {
      //   if (this.document.document_id) {
      //     await this.updateDocument();
      //   } else {
      //     await this.uploadDocument();
      //   }
      // }
    },
    updateDocument: async function () {
      if (this.document) {
        try {
          let documentData = {
            name: this.document.name,
            description: this.document.description,
          };

          let updateResult = await this.faqService.updateVehicleDocument(
            this.faq.faq_id,
            this.document.document_id,
            documentData,
          );
          console.log("Result", updateResult);
          let localDocument = this.$_.find(
            this.documents,
            (document) => document.document_id == this.document.document_id,
          );
          localDocument.name = documentData.name;
          localDocument.description = documentData.description;
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Update success",
                    close: onClose,
                  },
                },
                "Your document has been successfully updated.",
              );
            },
            {
              position: "top-right",
            },
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Update Error",
                    close: onClose,
                  },
                },
                "There was a problem updating your document, please try again later",
              );
            },
            {
              position: "top-right",
            },
          );
        }
      }
      this.document = {};
    },

    uploadDocument: async function () {
      if (this.document.document_id) {
        this.isBusy = true;
        try {
          await this.faqService.updateVehicleDocument(
            this.faq.faq_id,
            this.document.document_id,
            {
              ...this.document,
            },
          );

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Document Saved",
                    close: onClose,
                  },
                },
                "Your document was saved successfully",
              );
            },
            {
              position: "top-right",
            },
          );
          this.isBusy = false;
          this.$emit("complete");
        } catch (error) {
          this.isBusy = false;
          console.log(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Save Error",
                    close: onClose,
                  },
                },
                "There was a problem saving your document, please try again later",
              );
            },
            {
              position: "top-right",
            },
          );
        }
      } else {
        this.isBusy = true;
        try {
          console.log("Doc Before upload", this.document);
          let uploadResult = await this.faqService.createVehicleDocument(
            this.faq.faq_id,
            this.document,
          );
          console.log(uploadResult);

          let documentId = uploadResult.document_id;

          let accessUrl = uploadResult.access_url;
          console.log(accessUrl);
          let url = new URL(accessUrl);
          let accountUrl = url.origin;
          let sasToken = url.search;

          // Parse container
          let pathParts = url.pathname.split("/").filter((p) => {
            return p && p.trim() !== "";
          });
          let container = pathParts.length > 0 ? pathParts[0] : "";

          // Create filename
          let nonContainerParts = this.$_.drop(pathParts, 1);
          let filename = nonContainerParts.join("/");

          let blobService = AzureStorage.Blob.createBlobServiceWithSas(
            accountUrl,
            sasToken,
          );
          let customBlockSize =
            this.document.file[0].size > 1024 * 1024 * 32
              ? 1024 * 1024 * 4
              : 1024 * 128;
          blobService.singleBlobPutThresholdInBytes = customBlockSize;

          this.isUploading = true;
          let uploadSummary = blobService.createBlockBlobFromBrowserFile(
            container,
            filename,
            this.document.file[0],
            { blockSize: customBlockSize },
            async (error, result, response) => {
              if (error) {
                this.isUploading = false;
                // Upload blob failed
                this.$breadstick.notify(
                  ({ h, onClose }) => {
                    return h(
                      Notification,
                      {
                        props: {
                          type: "danger",
                          title: "Upload Error",
                          close: onClose,
                        },
                      },
                      "There was a problem uploading your document, please try again later",
                    );
                  },
                  {
                    position: "top-right",
                  },
                );
              } else {
                await this.faqService.updateVehicleDocument(
                  this.faq.faq_id,
                  documentId,
                  {
                    is_uploaded: true,
                  },
                );

                await this.loadDocuments();

                this.$breadstick.notify(
                  ({ h, onClose }) => {
                    return h(
                      Notification,
                      {
                        props: {
                          title: "Document Uploaded",
                          close: onClose,
                        },
                      },
                      "Your document was uploaded successfully",
                    );
                  },
                  {
                    position: "top-right",
                  },
                );
                this.isUploading = false;
                this.isBusy = false;
                this.$emit("complete");
              }
              this.uploadProgress = 0;
            },
          );

          uploadSummary.on("progress", () => {
            this.uploadProgress = parseFloat(
              uploadSummary.getCompletePercent(),
            );
          });
        } catch (error) {
          console.log(error);
        }
      }
      this.$emit("complete");
    },
    handleDeleteEvent: async function (result) {
      if (result) {
        this.confirmAlert.showing = false;

        try {
          await this.faqService.deleteDocumentById(
            this.faq.faq_id,
            this.confirmAlert.data.document_id,
          );
          let arrayIndex = this.$_.findIndex(this.documents, (a) => {
            return a.document_id == this.confirmAlert.data.document_id;
          });

          this.$delete(this.documents, arrayIndex);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Document Deleted",
                    close: onClose,
                  },
                },
                "Your document was deleted successfully",
              );
            },
            {
              position: "top-right",
            },
          );
        } catch (err) {
          console.error(err);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Delete Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting your document, please try again later",
              );
            },
            {
              position: "top-right",
            },
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    handleDeleteEventLink: async function (result) {
      if (result) {
        this.confirmAlert.showing = false;

        try {
          const resource = this.confirmAlert.data.resource_tags.filter(
            (e) => e.resource_id == this.faq.faq_id,
          )[0];
          await this.DocumentService.deleteResource(
            this.confirmAlert.data.document_id,
            resource.tag_id,
            resource,
          );
          let arrayIndex = this.$_.findIndex(this.documents, (a) => {
            return a.document_id == this.confirmAlert.data.document_id;
          });

          this.$delete(this.documents, arrayIndex);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Document Deleted",
                    close: onClose,
                  },
                },
                "Your document was deleted successfully",
              );
            },
            {
              position: "top-right",
            },
          );
        } catch (err) {
          console.error(err);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Delete Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting your document, please try again later",
              );
            },
            {
              position: "top-right",
            },
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteDocument: function (document) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteEvent,
        message: `Are you sure you want do delete '${
          document.name || document.original_filename
        }'? Cannot be undone`,
        showing: true,
        data: document,
      };
    },
    deleteDocumentLink: function (document) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteEventLink,
        message: `Are you sure you want do detach '${
          document.name || document.original_filename
        }'`,
        showing: true,
        data: document,
      };
    },
    viewDocument: function (document) {
      // this.viewingDocument = document;
      // this.viewingDocumentOpen = true;
    },
    getFileSize(sizeBytes) {
      let sizeKb = sizeBytes / 1024;
      return sizeKb > 1023
        ? `${(sizeKb / 1024).toFixed(2)} MB`
        : `${sizeKb.toFixed(2)} KB`;
    },
  },
};
</script>
